<template>
  <form class="contact-form" @submit.prevent="submit">
    <div class="col-md-6" :class="{ 'has-error': $v.form.name.$error }">
      <input ref="name" class="form-control input-box" id="name" type="text" v-model.trim="$v.form.name.$model" placeholder="First Name">
    </div>
    <div class="col-md-6" :class="{ 'has-error': $v.form.surname.$error }">
      <input class="form-control input-box" id="surname" type="text" v-model.trim="$v.form.surname.$model" placeholder="Family Name">
    </div>
    <div class="col-md-4" :class="{ 'has-error': $v.form.phone.$error }">
      <input class="form-control input-box" type="tel" v-model.trim="$v.form.phone.$model" placeholder="Mobile Phone">
    </div>
    <div class="col-md-8" :class="{ 'has-error': $v.form.email.$error }">
      <input class="form-control input-box" type="email" v-model.trim="$v.form.email.$model" placeholder="Email">
    </div>
    <div class="col-md-12" :class="{ 'has-error': $v.form.subject.$error }">
      <select class="form-control input-box" id="subject" v-model="$v.form.subject.$model">
        <option value="" disabled selected hidden>Please select a subject</option>
        <option value="preorder">Make a pre-order</option>
        <option value="ambassador">Become an ambassador</option>
        <option value="other">Something else...</option>
      </select>
    </div>
    <template v-if="isPreorder">
      <div class="col-md-12">
        Please leave your details and we reach out to you!
      </div>
      <div class="col-md-6" :class="{ 'has-error': $v.form.salon.$error }">
        <input class="form-control input-box" id="salon" type="text" v-model.trim="$v.form.salon.$model" placeholder="Salon">
      </div>
      <div class="col-md-6" :class="{ 'has-error': $v.form.vat.$error }">
        <input class="form-control input-box" id="vat" type="text" v-model.trim="$v.form.vat.$model" placeholder="VAT">
      </div>
      <div class="col-md-8" :class="{ 'has-error': $v.form.address.$error }">
        <input class="form-control input-box" type="text" v-model.trim="$v.form.address.$model" placeholder="Address">
      </div>
      <div class="col-md-4" :class="{ 'has-error': $v.form.housenr.$error }">
        <input class="form-control input-box" type="text" v-model.trim="$v.form.housenr.$model" placeholder="Number">
      </div>
      <div class="col-md-4" :class="{ 'has-error': $v.form.postalcode.$error }">
        <input class="form-control input-box" type="text" v-model.trim="$v.form.postalcode.$model" placeholder="Postal code">
      </div>
      <div class="col-md-8" :class="{ 'has-error': $v.form.city.$error }">
        <input class="form-control input-box" type="text" v-model.trim="$v.form.city.$model" placeholder="City">
      </div>
      <template v-if="false">
        <div class="col-md-3">
          <input class="form-control input-box" v-model="qty" type="number" name="qty" placeholder="Qty.">
        </div>
        <div class="col-md-9">
          <input class="form-control input-box result" v-model="qtyResult" type="text" name="qtyresult" readonly>
        </div>
      </template>
    </template>
    <div class="col-md-12" :class="{ 'has-error': $v.form.message.$error }">
      <textarea class="form-control textarea-box" v-model.trim="$v.form.message.$model" :rows="8"
                :placeholder="this.isPreorder ? 'Extra information' : 'Message'"></textarea>
    </div>
    <h4 v-show="submitStatus === 'OK'">
      <i class="icon_check"></i> Your message has been sent successfully.
    </h4>
    <h4 v-show="submitStatus === 'PENDING'">
      <i class="icon_check"></i> Sending your message...
    </h4>
    <h4 v-show="submitStatus === 'TRYAGAIN'">
      <i class="icon_error-circle"></i> Please try submitting the form again.
    </h4>
    <h4 v-show="submitStatus === 'ERROR'">
      <i class="icon_error-circle"></i> Please fill in the form correctly.
    </h4>
    <button class="btn hvr-bounce-to-right" type="submit" id="submit" name="submit" data-style="expand-left">
      Send Message
    </button>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {required, email, helpers} from 'vuelidate/lib/validators'

const mustContainNumberPattern =/.*[0-9]+.*/;
const mustContainNumber = (value) => !helpers.req(value) || mustContainNumberPattern.test(value)

export default {
  mixins: [validationMixin],
  name: 'Contact',
  props: {
    // msg: String
  },
  computed: {
    isPreorder() {
      return this.form.subject === 'preorder';
    },
    qtyResult() {
      return this.qty ? `€ ${this.calculateCost()} (${this.qty} x € XX,XX)` : ''
    }
  },
  data: () => ({
    submitStatus: null,
    form: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      salon: '',
      vat: '',
      address: '',
      housenr: '',
      postalcode: '',
      city: '',
    },
    qty: null,
  }),
  methods: {
    calculateCost() {
      return Math.round(this.qty * 6.99 * 100) / 100
    },
    submit() {
      console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        //https://formspree.io/f/xdopwvyz
        //https://formsubmit.co/83ff2beb311184e42195adfa7bc7c274
        // const formData = new FormData();
        // for ( let key in this.form ) {
        //   formData.append(key, this.form[key])
        // }
        fetch("sendmail.php",
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.form),
              method: "POST"
            })
        .then(() => this.submitStatus = 'OK')
        .catch(() => this.submitStatus = 'TRYAGAIN')
      }
    }
  },
  watch: {
    subject() {
      this.$forceNextTick(() => {
        document.getElementById('name').focus()
      })
    }
  },
  mounted() {
    window.VueBus.$on('subject', (s) => this.form.subject = s)
  },
  validations: {
    form: {
      name: {
        required,
        // alpha,
      },
      surname: {
        required,
        // alpha,
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      subject: {
        required
      },
      message: {
        required,
      },
      salon: {
        // required: requiredIf('isPreorder')
      },
      vat: {
        // required: requiredIf('isPreorder')
      },
      address: {
        // required: requiredIf('isPreorder')
      },
      housenr: {
        mustContainNumber,
        // required: requiredIf('isPreorder')
      },
      postalcode: {
        mustContainNumber,
        // required: requiredIf('isPreorder')
      },
      city: {
        // required: requiredIf('isPreorder')
      },
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-control.input-box.result[readonly] {
  cursor: auto;
  border: none;
}

.has-error, .has-error * {
  border-color: #a94442!important;
  color: #a94442!important;
}

/*h3 {*/
/*  margin: 40px 0 0;*/
/*}*/
/*ul {*/
/*  list-style-type: none;*/
/*  padding: 0;*/
/*}*/
/*li {*/
/*  display: inline-block;*/
/*  margin: 0 10px;*/
/*}*/
/*a {*/
/*  color: #42b983;*/
/*}*/
</style>
