import Vue from 'vue'
import VueForceNextTick from 'vue-force-next-tick'
Vue.use(VueForceNextTick)
import App from './App.vue'

Vue.config.productionTip = false

window.VueBus = new Vue();

new Vue({
  render: h => h(App),
}).$mount('#v-contact')

console.log('loaded');